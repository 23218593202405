<template>
  <div>
    <el-upload
      :action="uploadUrl"
      :data="formData"
      name="file"
      list-type="picture-card"
      :before-upload="beforeUpload"
      :on-success="uploadSuccess"
      :file-list="fileList"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>

          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { makeSign } from "../utils/tools";
export default {
  data() {
    return {
      fileList: [],
      uploadUrl: "",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      formData: { op: "files_upload" },
      imgAy: [],
    };
  },
  props: {
    imgList: {
      type: [],
      default: () => {},
    },
  },
  watch: {
    imgList(val) {
      console.log("upload-imgList", val);
      this.fileList = val;
      if (val.length > 0) {
        val.forEach((it) => {
          this.imgAy.push(it.url);
        });
      } else {
        this.imgAy = [];
      }
    },
  },
  mounted() {
    // console.log("upload", process.env.VUE_APP_BASE_URL);
    this.uploadUrl = process.env.VUE_APP_BASE_URL + "Upload.php";
    // this.fileList = [
    //   {
    //     url: "http://localhost/qingbao/upload/2022-03/62347c8927593.png",
    //   },
    // ];
  },
  methods: {
    //未上拍照 录入系统
    //
    uploadSuccess(response, file, fileList) {
      console.log(response, file, fileList);
      this.fileList = [{ url: response.path }];
      this.imgAy.push(response.path);
      this.$emit("uploadFiles", { imgs: this.imgAy });
    },
    beforeUpload() {
      let time = Math.floor(new Date().getTime() / 1000);
      let app_id = Math.ceil(Math.random() * 100000);
      let sign_obj = {
        time: time,
        app_id: app_id,
      };
      let sign = makeSign(sign_obj);
      sign_obj["sign"] = sign;
      Object.assign(this.formData, sign_obj);
    },
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
// .avatar-uploader .el-upload {
//   border: 1px dashed #d9d9d9;
//   border-radius: 6px;
//   cursor: pointer;
//   position: relative;
//   overflow: hidden;
// }
// .avatar-uploader .el-upload:hover {
//   border-color: #409eff;
// }
// .avatar-uploader-icon {
//   font-size: 28px;
//   color: #8c939d;
//   width: 178px;
//   height: 178px;
//   line-height: 178px;
//   text-align: center;
// }
// .avatar {
//   width: 178px;
//   height: 178px;
//   display: block;
// }
</style>
