<template>
  <el-dialog
    title="分类"
    :visible.sync="dialogVisible"
    width="600"
    :before-close="close"
  >
    <el-form ref="dataForm" label-width="80px" :rules="rules" :model="temp">
      <el-form-item label="标题" prop="sTitle">
        <el-input v-model="temp.sTitle"></el-input>
      </el-form-item>
      <el-form-item label="图片" prop="sPath">
        <Upload :imgList="imgAy" @uploadFiles="upload_Files" />
        <el-input v-model="temp.sPath"></el-input>
      </el-form-item>
      <el-form-item label="链接地址" prop="sUrl">
        <el-input v-model="temp.sUrl"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="sSort">
        <el-input v-model="temp.sSort"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import Upload from "../components/Upload.vue";
export default {
  components: { Upload },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    row_type: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    show(val, oVal) {
      if (val != oVal) {
        this.dialogVisible = val;
      }
      if (val) {
        this.upfun();
      }
    },
    row_type(val) {
      Object.assign(this.temp, val);
    },
  },
  data() {
    return {
      imgAy: [{ url: "" }],
      dialogVisible: false,
      temp: {
        id: "",
        sTitle: "",
        sPath: "",
        sUrl: "",
        sSort: "",
      },
      rules: {
        sTitle: [
          {
            required: true,
            message: "标题必填",
            trigger: "blur",
          },
        ],
        sPath: [
          {
            required: true,
            message: "图片必填",
            trigger: "blur",
          },
        ],
        sUrl: [
          {
            required: true,
            message: "链接地址必填",
            trigger: "blur",
          },
        ],
        sSort: [
          {
            required: true,
            message: "排序必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    upfun() {
      this.$nextTick(() => {
        // console.log(JSON.stringify(this.temp.tPath));
        this.imgAy = [{ url: this.temp.sPath }];
      });
    },
    upload_Files(ay) {
      // console.log(ay.imgs);
      let ay_img = ay.imgs;
      this.temp.sPath = ay_img[ay_img.length - 1];
    },
    update() {
      this.$emit("update");
    },
    close() {
      this.$emit("close");
    },
    save() {
      // console.log(this.temp);
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          // this.temp.id = 0; // mock a id
          this.$api.silders_save(this.temp).then((res) => {
            this.close();
            this.update();
            this.$notify({
              title: "温馨提示",
              message: res.data.msg,
              type: "success",
              duration: 2000,
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
